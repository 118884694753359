import { Severity } from "@/types/utils";
import { create } from "zustand";

interface State {
    visible: boolean;
    message: string;
    severity: Severity;
}

interface Actions {
    dismiss(): void;
}

const initialState: State = {
    visible: false,
    message: "",
    severity: "info",
};

export const useSnackbar = create<State & Actions>(set => ({
    ...initialState,
    dismiss: () => set(initialState),
}));

export const showSnackbar = (message: string, severity: Severity) =>
    useSnackbar.setState({ message, severity, visible: true });
