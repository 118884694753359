import React from "react";
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/config/firebase";
import { log } from "@/utils/logger";
import { useAdmin } from "@/context/admin";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { TextInput } from "@/module";
import { Styled } from "./styles";
import constant from "@/utils/constant";
import { showSnackbar } from "@/components/Snackbar/hooks";

const Login: React.FC = () => {
    const navigate = useNavigate();

    const { setGlobalLoading, globalLoading } = useAdmin();
    const { handleSubmit, control } = useForm({
        defaultValues: { email: "", password: "" },
    });

    const login = useAdmin(state => state.login);

    const onSubmit = async (form: { email: string; password: string }) => {
        try {
            setGlobalLoading(true);
            const { email, password } = form;
            if (email !== "admin@dippi.app") {
                return showSnackbar("Only admin users are allowed.", "error");
            }
            await signInWithEmailAndPassword(auth, email, password);
            login(), navigate("/");
        } catch (e) {
            log.error(e);
            setGlobalLoading(false);
        } finally {
            setGlobalLoading(false);
        }
    };

    return (
        <Styled.Container>
            <Styled.Content>
                <Styled.Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={constant.GRID_SPACING}>
                        <Grid item xs={12}>
                            <Typography variant="h3" textAlign="center" marginBottom={0}>
                                Welcome to Dippi admin portal
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="email"
                                control={control}
                                id="admin-email"
                                label="Email"
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="password"
                                id="admin-password"
                                label="Password"
                                type="password"
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={globalLoading} type="submit" fullWidth={true}>
                                Sign in
                            </Button>
                        </Grid>
                    </Grid>
                </Styled.Form>
            </Styled.Content>
        </Styled.Container>
    );
};

export default Login;
