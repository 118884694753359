import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            xmlns="http://www.w3.org/2000/svg"
            fill="#E4AF0A"
            d="M12.405 9.562a.5.5 0 0 0-.81 0l-3.353 4.645a.5.5 0 0 0 .405.793h6.706a.5.5 0 0 0 .405-.793l-3.353-4.645Z"
        />
    </svg>
);
export default SvgComponent;
