import { ComponentsOverrides, createTheme } from "@mui/material/styles";
import { Theme as MUITheme } from "@mui/system";
import type {} from "@mui/x-data-grid/themeAugmentation";

const MuiDataGridOverride: ComponentsOverrides<MUITheme>["MuiDataGrid"] = {
    root: ({ theme }) => ({
        border: "none",
        width: "76%",
        overflowX: "auto",
        padding: "16px 32px 32px 32px",
        borderRadius: "8px",
        background: theme.palette.secondary.dark,
        margin: "10px auto 0 auto",

        "& .MuiDataGrid-main": {
            minWidth: "960px",

            "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle":
                {
                    fontFamily: "Figtree",
                },
        },

        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within":
            {
                outline: "none",
            },
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders":
            {
                border: "none",
            },

        "& .MuiDataGrid-columnHeader": {
            padding: "0 24px",
        },

        "& .MuiDataGrid-virtualScroller": {
            padding: "10px 0",
            minHeight: "500px",
            overflow: "hidden",
        },

        "& .MuiDataGrid-row": {
            borderRadius: "4px",
            height: "70px",
            boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}`,
            transition: "0.3s",
            cursor: "pointer",
            background: theme.palette.secondary.dark,
            fontSize: "14px",
            fontWeight: 400,
            width: "calc(var(--DataGrid-rowWidth) - 2px)",

            "& .MuiDataGrid-cell": {
                padding: "0 24px",

                "&:last-of-type": {
                    padding: "0",
                },
            },

            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
            },
        },

        "& .MuiDataGrid-overlay": {
            background: "none",
            color: "white",
        },

        "& .MuiFormControl-root": {
            padding: "0 12px",

            "& .MuiInputBase-root": {
                color: theme.palette.secondary.light,
                gap: "4px",

                "& .MuiInputBase-input": {
                    color: "white",
                    margin: "0",
                    fontFamily: "Figtree",
                },

                "& .MuiButtonBase-root": {
                    display: "none",
                },

                "&:before, &:after": {
                    content: "none",
                },
            },
        },

        [theme.breakpoints.down("md")]: {
            width: "100%",
            borderRadius: "unset",
        },
    }),
};

const MuiPaperOverride: ComponentsOverrides<MUITheme>["MuiPaper"] = {
    root: ({ theme }) => ({
        borderRadius: "8px",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,

        "& .MuiList-root": {
            padding: "0",
        },

        "& .MuiButtonBase-root": {
            backgroundColor: theme.palette.secondary.dark,

            "& span": {
                "& .MuiSvgIcon-root": {
                    color: "white",
                },
            },

            "&:hover": {
                backgroundColor: theme.palette.primary.main,
                transition: "0.3s ease-in",
                color: "white",
            },
        },

        "& .MuiListSubheader-root": {
            width: "100%",
            background: "none",
            fontSize: "16px",
            color: "white",
            fontWeight: 700,

            "&:hover": {
                background: "none",
            },
        },

        "& li": {
            [theme.breakpoints.down("md")]: {
                minHeight: "unset",
            },
        },
    }),
};

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#6c5ecf",
            dark: "#4d4393",
            light: "#9d93df",
            "50": "#f0effa",
            "100": "#d1cdf0",
            "200": "#bbb5e9",
            "300": "#9d93df",
            "400": "#897ed9",
            "500": "#6c5ecf",
            "600": "#6256bc",
            "700": "#4d4393",
            "800": "#3b3472",
            "900": "#2d2757",
        },
        secondary: {
            main: "#202020",
            light: "#414141",
            dark: "#121212",
        },
        error: {
            main: "#ff726c",
            dark: "#b5514d",
            light: "#ffa19d",
            "50": "#fff1f0",
            "100": "#ffd3d1",
            "200": "#ffbebb",
            "300": "#ffa19d",
            "400": "#ff8e89",
            "500": "#ff726c",
            "600": "#e86862",
            "700": "#b5514d",
            "800": "#8c3f3b",
            "900": "#6b302d",
        },
        info: {
            main: "#73b6ff",
            dark: "#5281b5",
            light: "#a1ceff",
            "50": "#f1f8ff",
            "100": "#d4e8ff",
            "200": "#bfddff",
            "300": "#a1ceff",
            "400": "#8fc5ff",
            "500": "#73b6ff",
            "600": "#69a6e8",
            "700": "#5281b5",
            "800": "#3f648c",
            "900": "#304c6b",
        },
        success: {
            main: "#30db5b",
            dark: "#229b41",
            light: "#74e791",
            "50": "#eafbef",
            "100": "#bff4cc",
            "200": "#a0eeb4",
            "300": "#74e791",
            "400": "#59e27c",
            "500": "#30db5b",
            "600": "#2cc753",
            "700": "#229b41",
            "800": "#1a7832",
            "900": "#145c26",
        },
        warning: {
            main: "#ffb340",
            dark: "#b57f2d",
            light: "#ffcc7f",
            "50": "#fff7ec",
            "100": "#ffe7c4",
            "200": "#ffdca7",
            "300": "#ffcc7f",
            "400": "#ffc266",
            "500": "#ffb340",
            "600": "#e8a33a",
            "700": "#b57f2d",
            "800": "#8c6223",
            "900": "#6b4b1b",
        },
    },
    typography: {
        allVariants: {
            lineHeight: 1.68,
            fontWeight: 400,
            fontFamily: "Figtree",
        },
        h1: {
            fontSize: "40px",
            marginBottom: "16px",
        },
        h2: {
            fontSize: "32px",
            marginBottom: "12px",
        },
        h3: {
            fontSize: "24px",
            marginBottom: "12px",
        },
        h4: {
            fontSize: "20px",
            marginBottom: "12px",
        },
        h5: {
            fontSize: "16px",
            marginBottom: "8px",
        },
        h6: {
            fontSize: "14px",
            marginBottom: "8px",
        },
        body1: {
            fontFamily: "Ubuntu",
            fontSize: "16px",
        },
        body2: {
            fontFamily: "Ubuntu",
            fontSize: "14px",
        },
        caption: {
            fontSize: "12px",
        },
    },
    breakpoints: {
        values: {
            xs: 375,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#202020",
                    border: "1px solid #6c5ecf",
                    padding: "10px",
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    borderRadius: "12px",
                    maxWidth: "500px",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "#0A0A0A",
                    fontFamily: "Figtree",
                    fontSize: "14px",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                    },
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 100px #0A0A0A inset",
                        borderRadius: "unset",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "Figtree",
                    fontSize: "14px",
                },
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                componentsProps: {
                    typography: {
                        fontFamily: "Figtree",
                        variant: "body2",
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Figtree",
                    fontSize: "14px",
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    maxHeight: "36px",
                    textTransform: "unset",
                    padding: "8px 16px",
                    borderRadius: "8px",
                },
                text: {
                    background: "none",
                    boxShadow: "none",
                    padding: "0px",
                    "&:hover": {
                        background: "none",
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: MuiDataGridOverride,
        },
        MuiPaper: {
            styleOverrides: MuiPaperOverride,
        },
        MuiAlert: {
            defaultProps: {
                variant: "outlined",
            },
        },
    },
});

export default theme;
