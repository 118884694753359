import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <mask
            id="a"
            width={24}
            height={24}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "alpha",
            }}
        >
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#F9F9F9"
                d="M5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.702V5H5.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h6.702v1.5H5.308Zm10.923-4.23-1.039-1.085 2.435-2.435h-8.53v-1.5h8.53l-2.435-2.435 1.039-1.084L20.5 12l-4.27 4.27Z"
            />
        </g>
    </svg>
);
export default SvgComponent;
