import React from "react";
import * as MUI from "@mui/material";
import { useSnackbar } from "./hooks";

const Alert = React.forwardRef<HTMLDivElement, MUI.AlertProps>(function Alert(props, ref) {
    return <MUI.Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar: React.FC = () => {
    const { message, severity, visible, dismiss } = useSnackbar();

    if (!visible) return null;

    return (
        <MUI.Snackbar
            open={visible}
            autoHideDuration={4000}
            onClose={dismiss}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                onClose={dismiss}
                severity={severity}
                sx={{
                    color: "white",
                    border: "none",
                    "& .MuiButtonBase-root": {
                        "&:hover": {
                            background: "#2A2A2A",
                        },
                    },
                }}
            >
                {message}
            </Alert>
        </MUI.Snackbar>
    );
};

export default Snackbar;
