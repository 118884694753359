import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#E4AF0A"
            d="M11.595 14.438a.5.5 0 0 0 .81 0l3.353-4.645A.5.5 0 0 0 15.353 9H8.647a.5.5 0 0 0-.405.793l3.353 4.645Z"
        />
    </svg>
);
export default SvgComponent;
