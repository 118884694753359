import React, { useState } from "react";
import { Styled } from "../styles";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import constant from "@/utils/constant";
import { Subscription } from "@/types/entities";
import { Controller, useForm } from "react-hook-form";
import { Forms } from "@/types/form";
import schemas from "@/utils/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select, TextInput } from "@/module";
import { SELECT_ITEMS } from "@/utils/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { apiRequest } from "@/api";
import { log } from "@/utils/logger";
import { useParams } from "react-router-dom";

interface Props {
    subscription: Subscription;
}

const Form: React.FC<Props> = ({ subscription }) => {
    const { oid } = useParams();
    const queryClient = useQueryClient();

    const { plan, brands, labels, hasMarketing, hasPriorityAccess, productGroups, media } =
        subscription;

    const [loading, setLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<Forms["Subscription"]>({
        resolver: yupResolver(schemas.subscription),
        values: {
            plan,
            media,
            labels,
            brands,
            hasMarketing,
            hasPriorityAccess,
            productGroups,
        },
    });

    const onSubmit = async (form: Forms["Subscription"]) => {
        try {
            setLoading(true);
            await apiRequest("PUT", `/organizations/${oid}/subscription`, { data: form });
            await queryClient.refetchQueries({ queryKey: ["organization", oid], exact: true });
        } catch (e) {
            log.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Styled.Form onSubmit={handleSubmit(async form => await onSubmit(form))}>
            <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
                <Grid item xs={12}>
                    <Typography variant="h3">Subscription information</Typography>
                </Grid>
                <Grid item xl={2} md={2.5} sm={4} xs={12}>
                    <Select
                        control={control}
                        name="plan"
                        label="Plan"
                        id="sub-plan"
                        items={SELECT_ITEMS.subscriptionPlans}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextInput
                        control={control}
                        name="brands"
                        label="Brands"
                        id="sub-brands"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextInput
                        control={control}
                        name="labels"
                        label="Digital labels"
                        id="sub-labels"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <Controller
                            name="hasMarketing"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                disableRipple={true}
                                            />
                                        }
                                        label="Has access to marketing"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <Controller
                            name="hasPriorityAccess"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                disableRipple={true}
                                            />
                                        }
                                        label="Has priority access"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xl={6} md={6} sm={10} xs={12}>
                        <Select
                            control={control}
                            name="productGroups"
                            label="Product groups"
                            id="sub-product-groups"
                            items={SELECT_ITEMS.productGroups}
                            fullWidth={true}
                            multiple={true}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Grid item xl={1.5} md={2} sm={4} xs={12}>
                        <TextInput
                            control={control}
                            name="media.images"
                            label="Images"
                            id="sub-media-images"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={1.5} md={2} sm={4} xs={12}>
                        <TextInput
                            control={control}
                            name="media.storage"
                            label="Storage"
                            id="sub-media-storage"
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Button type="submit" disabled={loading}>
                        Save
                    </Button>
                    <Button type="button" variant="outlined" onClick={() => reset()}>
                        Cancel
                    </Button>
                </Grid>
            </Styled.Content>
        </Styled.Form>
    );
};

export default Form;
