import React from "react";
import { CircularProgress } from "@mui/material";
import { Styled } from "./styles";

interface Props {
    message?: string;
    loading: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Backdrop: React.FC<Props> = ({ message, loading, setLoading }) => {
    return (
        <Styled.Backdrop open={loading} onClick={setLoading?.bind(this, false)}>
            <CircularProgress color="inherit" />
            {message ? <p>Connecting</p> : null}
        </Styled.Backdrop>
    );
};

export default Backdrop;
