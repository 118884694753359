import { Logger } from "@/types/utils";
import { showSnackbar } from "@/components/Snackbar/hooks";

export const log: Logger = {
    success: (message: string) => {
        showSnackbar(message, "success");
    },
    info: (message: string) => {
        showSnackbar(message, "info");
    },
    warn: (message: string) => {
        showSnackbar(message, "warning");
    },
    error: (error: unknown) => {
        let message = "Unknown error";

        if (error instanceof Error) {
            if (error.name === "AbortError") message = "Request has been canceled our timed out";
            else message = error.message;
        }

        showSnackbar(message, "error");
    },
};
