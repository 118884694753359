import FoodModule from "remote/Food";
import FurnitureModule from "remote/Furniture";
import WineModule from "remote/Wine";
import DistilledAlcoholModule from "remote/DistilledAlcohol";
import OtherBeverageModule from "remote/OtherBeverage";

import SelectModule from "remote/Select";
import TextInputModule from "remote/TextInput";
import HelpIconModule from "remote/HelpIcon";
import RichTextModule from "remote/RichText";

import { Product, Subscription } from "./types/entities";
import { ApiConfig, ApiMethod, Logger } from "./types/utils";
import { Control } from "react-hook-form";
import { SelectProps, TextFieldProps } from "@mui/material";

export interface ProductProps {
    subscription: Subscription;
    product: Product | undefined;
    log: Logger;
    apiRequest: <D>(method: ApiMethod, url: string, config?: ApiConfig) => Promise<D>;
}

const Food: React.FC<ProductProps> = FoodModule;
const Furniture: React.FC<ProductProps> = FurnitureModule;
const Wine: React.FC<ProductProps> = WineModule;
const DistilledAlcohol: React.FC<ProductProps> = DistilledAlcoholModule;
const OtherBeverage: React.FC<ProductProps> = OtherBeverageModule;

type TextInputProps = {
    name: string;
    control: Control<any>;
} & TextFieldProps;
const TextInput: React.FC<TextInputProps> = TextInputModule;

const Select: React.FC<
    {
        name: string;
        control: Control<any>;
        items: Array<{ value: string; label: string }>;
    } & SelectProps
> = SelectModule;

const RichText: React.FC<{
    onChange: (...event: string[]) => void;
    description: string | undefined;
    fieldType: string;
}> = RichTextModule;

interface HelpIconProps {
    title: string;
    src?: string;
    color?: string;
}

const HelpIcon: React.FC<HelpIconProps> = HelpIconModule;

export {
    Furniture,
    Food,
    Wine,
    DistilledAlcohol,
    OtherBeverage,
    TextInput,
    Select,
    RichText,
    HelpIcon,
};
