import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderTop } from "./widgets";

const Layout: React.FC = () => {
    return (
        <React.Fragment>
            <Box position="fixed" width="100%" zIndex={99}>
                <HeaderTop />
            </Box>
            <Box minHeight="56px" maxHeight="56px" />
            <Outlet />
        </React.Fragment>
    );
};

export default Layout;
